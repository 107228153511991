
import { defineComponent, ref, computed } from 'vue'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsRow from '@web/components/AsRow.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsDayTabs from '@web/components/AsDayTabs.vue'
import AsAlertRenewCourseMobile from '@web/components/AsAlertRenewCourseMobile.vue'
import AcDay from '@web/components.v2/Day/index.vue'
import BottomNavigation from '@web/components.v2/BottomNavigation.vue'

import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import { DayStatus } from '@web/store/types/modules/stream'
import { useDisplay } from '@ui-kit/composables'

export default defineComponent({
  name: 'VDay',
  components: {
    AsAlertRenewCourseMobile,
    AsHeaderMobile,
    AsRow,
    AsMenu,
    AsHeaderMain,
    AsFooter,
    AsBreadcrumb,
    AsDayTabs,
    AcDay,
    BottomNavigation
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const platform = useDisplay().getPlatform()
    const isMobileView = platform.tablet || platform.ios || platform.android || platform.cordova || (platform.mac && platform.touch) || (platform.touch && !platform.win)

    const loaded = ref(false)
    const nextDayLoaded = ref(true)
    const isOpenMenu = ref(false)
    const dayId = ref(Number(router.currentRoute.value.params.dayId || 0))

    const day = computed(() => store.getters['day/getDayById'](dayId.value))
    const streamId = computed<number>(() => Number(router.currentRoute.value.params.streamId))
    const stream = computed(() => store.getters['stream/getStreamById'](streamId.value))
    const course = computed<Course | undefined>(() => {
      if (!stream.value) {
        return undefined
      }
      return store.getters['course/getCourseById'](stream.value.courseId)
    })
    const courseTitle = computed(() => course.value?.title || '')
    const dayStatus = computed<DayStatus | undefined>(() => store.getters['stream/getDayStatus']({ streamId: streamId.value, dayId: dayId.value }))
    const progress = computed(() => {
      if (!dayStatus.value || dayStatus.value.id === 0) {
        return 0
      }
      const dayStatistic = store.getters['stream/getDayStatistic'](dayStatus.value.id)
      if (!dayStatistic) {
        return 0
      }
      const viewedSeconds = dayStatistic.viewedSeconds || 0

      const finishTotalTime = day.value ? Math.max(day.value?.finishTotalTime, 1) : 1
      return Math.min(Math.ceil(viewedSeconds / finishTotalTime * 100), 100)
    })
    const title = computed(() => {
      return day.value?.title
    })

    function onDayLoaded (payload) {
      loaded.value = payload.loaded
      if (dayId) {
        dayId.value = payload.dayId
      }
    }

    return {
      title,
      loaded,
      nextDayLoaded,
      isOpenMenu,
      menu: computed(() => store.getters['menu/general']),
      course,
      day,
      stream,
      progress,
      courseTitle,
      streamId,
      onDayLoaded,
      isMobileView
    }
  }
})
