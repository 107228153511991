import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_alert_renew_course_mobile = _resolveComponent("as-alert-renew-course-mobile")!
  const _component_as_day_tabs = _resolveComponent("as-day-tabs")!
  const _component_ac_day = _resolveComponent("ac-day")!
  const _component_bottom_navigation = _resolveComponent("bottom-navigation")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["lCommon page-day", `${!_ctx.isMobileView && 'bg-blue'}`]),
    "data-cy": "page_day"
  }, [
    (_ctx.isMobileView)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.loaded)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.course)
                  ? (_openBlock(), _createBlock(_component_as_header_mobile, {
                      key: 0,
                      title: _ctx.courseTitle,
                      "prev-page": { name: 'calendar', params: { id: _ctx.streamId } },
                      "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
                    }, null, 8, ["title", "prev-page"]))
                  : _createCommentVNode("", true),
                _withDirectives(_createVNode(_component_as_alert_renew_course_mobile, { stream: _ctx.stream }, null, 8, ["stream"]), [
                  [_vShow, !_ctx.isOpenMenu]
                ]),
                (_ctx.day)
                  ? _withDirectives((_openBlock(), _createBlock(_component_as_day_tabs, {
                      key: 1,
                      day: _ctx.day,
                      course: _ctx.course,
                      "stream-id": _ctx.streamId,
                      progress: _ctx.progress
                    }, null, 8, ["day", "course", "stream-id", "progress"])), [
                      [_vShow, !_ctx.isOpenMenu]
                    ])
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_component_ac_day, {
            onLoaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDayLoaded($event))),
            onNextDayLoaded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nextDayLoaded = $event))
          }, null, 512), [
            [_vShow, !_ctx.isOpenMenu]
          ]),
          (_ctx.loaded)
            ? _withDirectives((_openBlock(), _createBlock(_component_bottom_navigation, {
                key: 1,
                stream: _ctx.stream,
                "current-page": "course",
                course: _ctx.course
              }, null, 8, ["stream", "course"])), [
                [_vShow, !_ctx.isOpenMenu]
              ])
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_as_header_main, { class: "page-day_header" }, {
            breadcrumb: _withCtx(() => [
              _createVNode(_component_as_breadcrumb, { title: _ctx.title }, null, 8, ["title"])
            ]),
            _: 1
          }),
          _createVNode(_component_as_row, { class: "page-day_contentWrapper" }, {
            menu: _withCtx(() => [
              _createVNode(_component_as_menu, {
                class: "page-day_menu",
                mini: true,
                menu: _ctx.menu,
                type: "sidebar"
              }, null, 8, ["menu"])
            ]),
            content: _withCtx(() => [
              _createVNode(_component_as_row, null, {
                content: _withCtx(() => [
                  _createVNode(_component_ac_day, {
                    onLoaded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDayLoaded($event))),
                    onNextDayLoaded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.nextDayLoaded = $event))
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_as_footer, { class: "page-day_footer" })
        ], 64))
  ], 2))
}